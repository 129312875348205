import React, { useEffect, useRef, useState, useCallback } from "react";
import { ColQueryOp, RoomScan } from "../../types";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";
import { getRoomScansFiles } from "../../networking/room_scan";
import qs from "qs";
import { useRenderer } from "../../hooks/threeJs/useRenderer";
import { useWindowResizeHandler } from "../../hooks/threeJs/useWindowResizeHandler";
import { useModelLoader } from "../../hooks/threeJs/useModelLoader";
import { getRoomDimension } from "../../util";
import { useZenerCeilingPos } from "../../hooks/threeJs/useZenerCeilingPos";
import * as THREE from "three";
import ReactPlayer from "react-player";
import Loading from "../../components/Loading";
import { Box, Button, Paper, Typography } from "@mui/material";
import "./SimulateDetails.scss";
import IrradianceHeatmapView from "../../components/IrradianceHeatmapView";

export interface RoomDimension {
  x: number; // Width
  y: number; // Depth
  z: number; // Height
}

export default function SimulateDetails(): React.ReactElement {
  const [roomScan, setRoomScan] = useState<RoomScan | null>(null);
  const roomScansCTSRef = useRef<CancelTokenSource | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [modelUrl, setModelUrl] = useState<string | null>(null);
  const [roomSize, setRoomSize] = useState<RoomDimension | null>(null);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [simulationActive, setSimulationActive] = useState<boolean>(false);

  const { scene, camera, renderer } = useRenderer(canvasRef);
  const { loadModel } = useModelLoader(scene, camera, renderer, modelUrl, roomSize);
  useWindowResizeHandler(canvasRef, camera, renderer);
  const [model, setModel] = useState<THREE.Object3D | null>(null);
  const [originalChildren, setOriginalChildren] = useState<THREE.Object3D[]>([]);
  // Hook to manage Zener positions
  const { zenerPositions, addZenerPosition, removeAllZeners } = useZenerCeilingPos(scene, camera, renderer, roomSize, canvasRef);

  function cancelRequest(cts: React.MutableRefObject<CancelTokenSource | null>) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  const fetchRoomScan = useCallback(async (scan_id: string) => {
    setLoading(true);

    const cancelTimeout = setTimeout(() => {
      cancelRequest(roomScansCTSRef);
    }, 20);

    try {
      const [fetchedRoomScan] = await getRoomScansFiles(
        { scan_id: { value: scan_id, op: ColQueryOp.Eq } },
        roomScansCTSRef.current
      );

      if (!fetchedRoomScan?.files) {
        enqueueSnackbar("No room scan data found.", { variant: "warning" });
        return;
      }

      setRoomScan(fetchedRoomScan);
      const { json, obj, mp4 } = fetchedRoomScan.files;
      if (!json || !obj || !mp4) {
        enqueueSnackbar("Incomplete room scan files.", { variant: "warning" });
        return;
      }
    
      setRoomScan(fetchedRoomScan);
      setRoomSize(getRoomDimension(JSON.parse(json)));
      setVideoUrl(mp4);
    
      const objBlob = new Blob([obj], { type: "text/plain" });
      setModelUrl(URL.createObjectURL(objBlob));
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log("Fetch canceled by user");
      } else {
        console.error(error);
        enqueueSnackbar("Failed to fetch room scan data.", { variant: "error" });
      }
    } finally {
      clearTimeout(cancelTimeout);
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  useEffect(() => {
    const queryObj = qs.parse(window.location.search.slice(1));
    const scan_id = queryObj.scan_id;
    if (scan_id) {
      fetchRoomScan(scan_id as string);
    } else {
      enqueueSnackbar("Scan ID is missing in the query.", { variant: "warning" });
    }
  }, [fetchRoomScan, enqueueSnackbar]);

  useEffect(() => {
    const loadAndAddModel = async () => {
      if (roomScan && modelUrl && !simulationActive) {
        try {
          const model = await loadModel();
          setModel(model);
        } catch (error) {
          console.error("Failed to load model:", error);
        }
      }
    };
    
    loadAndAddModel();
  }, [modelUrl, roomScan, loadModel, simulationActive]);

  useEffect(() => {
    if (scene) {
      setOriginalChildren([...scene.children]);
    }
  }, [modelUrl]);

  const animationThreadIdRef = useRef(0);

  useEffect(() => {
    // Increment the animation ID each time when the useEffect is called
    animationThreadIdRef.current++;
    const threadId = animationThreadIdRef.current;
    const animate = () => {
      if (threadId == animationThreadIdRef.current && renderer && scene && camera) {
        renderer.render(scene, camera);
        requestAnimationFrame(animate);
      }
    };
    animate();
    return () => {
      animationThreadIdRef.current++;
    };
  }, [renderer, scene, camera]);

  const handleSimulation = async () => {
    if (simulationActive) {
      setSimulationActive(false);
      // Clear the scene
      scene.clear();
      // Restore the original children
      originalChildren.forEach((child) => {
        scene.add(child.clone());
      });
      setModel(null);
      removeAllZeners();
    } else {
      const model = await loadModel();
      setModel(model);
      setSimulationActive(true);
    }
  };

  const showIrradiance = simulationActive && model && zenerPositions;

  return (
    <Box className="content-container" sx={{ padding: 2, textAlign: "center" }}>
      {showIrradiance && 
        <IrradianceHeatmapView scene={scene} model={model} lightPositions={zenerPositions} renderer={renderer} camera={camera} canvas={canvasRef} />
      }
      <Loading loading={loading}>
        <></>
      </Loading>

      <canvas
        ref={canvasRef}
        style={{
          width: "1000px",
          height: "500px",
          visibility: loading ? "hidden" : "visible",
          margin: "0 auto",
        }}
      />
      {!loading && 
      <>
        <Typography>
          {zenerPositions.length === 0 ? "You need to add a Zener first" : `Total Zeners: ${zenerPositions.length}`}
        </Typography>
        <Button variant="contained" color="primary" onClick={handleSimulation} disabled={zenerPositions.length === 0 || !model}>
          {simulationActive ? "Stop Simulation" : "Start Simulation"}
        </Button>
        <Button variant="contained" color="secondary" onClick={addZenerPosition} sx={{ ml: 2 }}>
          Add Zener
        </Button>
      </>
      }

      <Paper>
        {zenerPositions.map((pos, index) => (
          <Typography key={index} variant="subtitle1">
            {`Zener ${index + 1}: X: ${pos.x.toFixed(2)}, Y: ${pos.y.toFixed(2)}, Z: ${pos.z.toFixed(2)}`}
          </Typography>
        ))}
      </Paper>

      <Paper>
        {roomSize && (
          <Typography variant="subtitle1">
            {`The size of the room ${roomSize.x.toFixed(2)}(m), ${roomSize.y.toFixed(2)}(m), ${roomSize.z.toFixed(2)}(m)`}
          </Typography>
        )}
      </Paper>

      {videoUrl && (
        <>
          <Button variant="contained" color="primary" onClick={() => setShowVideo(prev => !prev)}>
            {showVideo ? "Hide Video" : "Display Video"}
          </Button>
          {showVideo && (
            <Box sx={{ mt: 2, height: "450px" }}>
              <ReactPlayer 
                url={videoUrl} 
                controls 
                playing 
                height="100%" 
              />
            </Box>
          )}
        </>
      )}

    </Box>
  );
}
