import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { CoredumpMetadata, FwVersionHistEntry, FwMetadata, OtaJob, OtaDevices } from "../types";
import * as Utils from "../util";
import { v4 as uuid } from "uuid";

const ADMIN_URL = "/admin";
const COREDUMP_URL = `${ADMIN_URL}/coredump`;
const COREDUMPS_URL = `${ADMIN_URL}/coredumps`;
const COREDUMPS_COUNT_URL = `${COREDUMPS_URL}/count`;
const DEVICES_URL = `${ADMIN_URL}/devices`;
const FIRMWARE_VERSION_HIST_URL = `${ADMIN_URL}/ota/version-hist`;
const FIRMWARE_VERSION_HIST_COUNT_URL = `${FIRMWARE_VERSION_HIST_URL}/count`;
const FIRMWARE_METADATA_URL = `${ADMIN_URL}/ota/firmware-metadata`;
const FIRMWARE_METADATA_TAGS_FILTER_URL = `${FIRMWARE_METADATA_URL}/tags-filter`;
const FIRMWARE_METADATA_COUNT_URL = `${FIRMWARE_METADATA_URL}/count`;
const FIRMWARE_BINARY_URL = `${ADMIN_URL}/ota/firmware-binary`;
const FIRMWARE_ROLLBACK_URL = `${ADMIN_URL}/ota/rollback`;
const OTA_NOTIFY_URL = `${ADMIN_URL}/ota/notify`;
const OTA_JOBS_URL = `${ADMIN_URL}/ota/jobs`;
const OTA_DEVICE_URL = `${ADMIN_URL}/ota/devices`;
const OTA_CANCEL_JOB_URL = `${OTA_JOBS_URL}/cancel`;
const OTA_JOBS_COUNT_URL = `${OTA_JOBS_URL}/count`;
const ALL_USER_GROUPS_INFO_URL = `${ADMIN_URL}/user-groups/info`;
const ADD_USER_TO_GROUP_URL = `${ADMIN_URL}/user-groups/add`;
const REMOVE_USER_FROM_GROUP_URL = `${ADMIN_URL}/user-groups/remove`;

export enum UserGroup {
  Uvx = "uvx",
}

export async function getCoredumps(
  deviceId?: string,
  offset?: number,
  count?: number,
  cancelTokenSource?: CancelTokenSource
): Promise<CoredumpMetadata[] | null> {
  const resp: AxiosResponse = await axios.get(COREDUMPS_URL, {
    params: {
      device_id: deviceId,
      offset,
      count,
    },
    cancelToken: cancelTokenSource?.token,
  });

  if (!Array.isArray(resp.data)) {
    // eslint-disable-next-line no-console
    console.warn(`${COREDUMPS_URL} payload not array: `, resp.data);
    return null;
  }

  return resp.data.map((cd) => ({
    deviceId: cd.device_id,
    coredumpId: cd.coredump_id,
    coredumpLen: cd.coredump_len,
    createdAt: new Date(cd.created_at),
  }));
}

export async function getCoredumpsCount(
  deviceId?: string,
  cancelTokenSource?: CancelTokenSource
): Promise<number> {
  const resp: AxiosResponse = await axios.get(COREDUMPS_COUNT_URL, {
    params: { device_id: deviceId },
    cancelToken: cancelTokenSource?.token,
  });

  return resp.data.count;
}

export async function getCoredump(
  deviceId: string,
  coredumpId: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  const resp: AxiosResponse = await axios.get(COREDUMP_URL, {
    params: {
      device_id: deviceId,
      coredump_id: coredumpId,
    },
    responseType: "arraybuffer",
    cancelToken: cancelTokenSource?.token,
    headers: { Accept: "application/octet-stream" },
  });

  const fileUrl = window.URL.createObjectURL(new Blob([resp.data]));
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", `${deviceId}-${coredumpId}.elf`);
  document.body.appendChild(link);
  link.click();
}

export async function deleteDevice(
  deviceId: string,
  cancelTokenSource?: CancelTokenSource
): Promise<AxiosResponse | null> {
  try {
    const resp: AxiosResponse = await axios.delete(DEVICES_URL, {
      params: { device_id: deviceId },
      cancelToken: cancelTokenSource?.token,
    });
    return resp;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function getFirmwareVersionHist(
  offset?: number,
  count?: number,
  cancelTokenSource?: CancelTokenSource
): Promise<FwVersionHistEntry[] | null> {
  try {
    const resp: AxiosResponse = await axios.get(FIRMWARE_VERSION_HIST_URL, {
      params: { offset, count },
      cancelToken: cancelTokenSource?.token,
    });
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function getFirmwareVersionHistCount(
  cancelTokenSource?: CancelTokenSource
): Promise<number | null> {
  try {
    const resp: AxiosResponse = await axios.get(
      FIRMWARE_VERSION_HIST_COUNT_URL,
      { cancelToken: cancelTokenSource?.token }
    );
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function getFirmwareMetadatas(
  offset?: number,
  count?: number,
  cancelTokenSource?: CancelTokenSource
): Promise<FwMetadata[] | null> {
  try {
    const resp: AxiosResponse = await axios.get(FIRMWARE_METADATA_URL, {
      params: {
        offset,
        count,
      },
      cancelToken: cancelTokenSource?.token,
    });
    const fwMetadatas: FwMetadata[] = Utils.keysToCamelCase(resp.data);
    return fwMetadatas;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function getFirmwareMetadatasCount(
  cancelTokenSource?: CancelTokenSource
): Promise<number | null> {
  try {
    const resp: AxiosResponse = await axios.get(FIRMWARE_METADATA_COUNT_URL, {
      cancelToken: cancelTokenSource?.token,
    });
    return resp.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error) || error.response.status === 404) {
      return null;
    }

    throw error;
  }
}

export async function putFirmwareBinaryFile(
  file: File,
  version: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    const formData = new FormData();
    formData.append("fw_binary", file, file.name);
    formData.append("version", version);
    await axios.put(FIRMWARE_BINARY_URL, formData, {
      headers: { "Content-Type": "multipart/form-data" },
      cancelToken: cancelTokenSource?.token,
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error)) {
      return;
    }

    throw error;
  }
}

export async function postFirmwareBinaryUpdateTagsFilter(
  version: string,
  tagsFilter: number[] | null,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    await axios.post(
      FIRMWARE_METADATA_TAGS_FILTER_URL,
      { version, tags_filter: tagsFilter },
      { cancelToken: cancelTokenSource?.token }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error)) {
      return;
    }

    throw error;
  }
}

export async function postFirmwareRollback(
  version: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  const params = new URLSearchParams({ version: version }).toString();
  const url = `${FIRMWARE_ROLLBACK_URL}?${params}`;
  try {
    await axios.post(
      url,
      { version },
      { cancelToken: cancelTokenSource?.token }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error)) {
      return;
    }

    throw error;
  }
}

export async function postNotifyOTA(
  version: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    await axios.post(
      OTA_NOTIFY_URL,
      { version },
      { cancelToken: cancelTokenSource?.token }
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    if (Axios.isCancel(error)) {
      return;
    }

    throw error;
  }
}

export async function postOtaJobs(
  version: string,
  tags?: number[],
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    const evt_type = "OTA_START";
    await axios.post(
      OTA_JOBS_URL,
      {
        job_id: uuid(),
        evt_type,
        payload: {
          firmwareVersion: version,
          tagIds: tags,
        }
      },
      { cancelToken: cancelTokenSource?.token }
    );

  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function getOtaJobs(offset?: number, count?: number, cancelTokenSource?: CancelTokenSource): Promise<OtaJob[]> {
  try {
    const resp: AxiosResponse = await axios.get(OTA_JOBS_URL, {
      params: { offset, count },
      cancelToken: cancelTokenSource?.token,
    });

    const jobs: OtaJob[] = resp.data.map((job: OtaJob) => ({
      ...job,
      job_start_ts: new Date(job.job_start_ts)
    }));

    return jobs;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function getDevicesByJobId(jobId: string, cancelTokenSource?: CancelTokenSource): Promise<OtaDevices[]> {
  try {
    const resp: AxiosResponse<OtaDevices[]> = await axios.get(OTA_DEVICE_URL, {
      params: { jobId },
      cancelToken: cancelTokenSource?.token,
    });

    const devices: OtaDevices[] = resp.data.map((device: OtaDevices) => ({
      ...device,
      latest_evt_ts: new Date(device.latest_evt_ts)
    }));

    return devices;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function cancelOtaJob(jobId: string, cancelTokenSource?: CancelTokenSource): Promise<void> {
  try {
    await axios.post(
      OTA_CANCEL_JOB_URL,
      {
        jobId,
      },
      { cancelToken: cancelTokenSource?.token }
    );

  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}


export async function getOtaJobCount(cancelTokenSource?: CancelTokenSource): Promise<number|null> {
  try {
    const resp: AxiosResponse = await axios.get(OTA_JOBS_COUNT_URL, {
      cancelToken: cancelTokenSource?.token
    });
    return resp.data;
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}
export interface UserGroupWithInfo {
  name: string,
  isMember: boolean
}

export async function getAllUserGroupsWithInfo(  
  cancelTokenSource?: CancelTokenSource
): Promise<UserGroupWithInfo[]> {
  try {
    const response = await axios.get(ALL_USER_GROUPS_INFO_URL, {
      cancelToken: cancelTokenSource?.token,
    });

    const groups = response.data;
    return groups;

  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function addUserToGroup(
  groupName: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    await axios.put(
      ADD_USER_TO_GROUP_URL, 
      { user_group: groupName },
      { cancelToken: cancelTokenSource?.token }
    );
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}

export async function removeUserFromGroup(
  groupName: string,
  cancelTokenSource?: CancelTokenSource
): Promise<void> {
  try {
    await axios.delete(REMOVE_USER_FROM_GROUP_URL, {
      cancelToken: cancelTokenSource?.token,
      params: { user_group: groupName } 
    });
  } catch (error) {
    if (Axios.isCancel(error)) {
      return;
    }
    throw error;
  }
}