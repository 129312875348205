import * as CvrtCase from "change-case";
import * as UvxZenerCapnp from "./capnp/ts_gen/uvx_zener.capnp";
import * as THREE from "three";
import { calculateIrrWithAkima } from "./lib/Akima";

export function zenerStateCapnpToStr(
  zenerState: UvxZenerCapnp.UvxZenerState,
): string {
  switch (zenerState) {
    case UvxZenerCapnp.UvxZenerState.START:
      return "START";
    case UvxZenerCapnp.UvxZenerState.INITIALIZING:
      return "INITIALIZING";
    case UvxZenerCapnp.UvxZenerState.UNCONFIGURED:
      return "UNCONFIGURED";
    case UvxZenerCapnp.UvxZenerState.ENABLED:
      return "ENABLED";
    case UvxZenerCapnp.UvxZenerState.DISABLED:
      return "DISABLED";
    case UvxZenerCapnp.UvxZenerState.OTA_UPDATING:
      return "OTA_UPDATING";
    case UvxZenerCapnp.UvxZenerState.ERROR:
      return "ERROR";
    case UvxZenerCapnp.UvxZenerState.RESTART:
      return "RESTART";
    default:
      return "INVALID";
  }
}

function cvrtObjKeys<I = object, O = object>(
  obj: I,
  cvrter: (inp: string) => string,
): O {
  return Object.entries(obj).reduce(function (
    acc: object,
    [key, val]: [string, any], // eslint-disable-line @typescript-eslint/no-explicit-any
  ) {
    const cvrtKey: string = cvrter(key);
    acc[cvrtKey] = val;
    return acc;
  }, {}) as O;
}

export function keysToCamelCase<I = object, O = object>(inp: I): O;
export function keysToCamelCase<I = object, O = object>(inp: I[]): O[];
export function keysToCamelCase<I = object, O = object>(inp: I[] | I): O[] | O {
  if (Array.isArray(inp)) {
    return <O[]>(<I[]>inp).map((i: I) => cvrtObjKeys(i, CvrtCase.camelCase));
  }

  return <O>cvrtObjKeys(<I>inp, CvrtCase.camelCase);
}

export function keysToSnakeCase<I = object, O = object>(inp: I): O;
export function keysToSnakeCase<I = object, O = object>(inp: I[]): O[];
export function keysToSnakeCase<I = object, O = object>(inp: I[] | I): O[] | O {
  if (Array.isArray(inp)) {
    return <O[]>(<I[]>inp).map((i: I) => cvrtObjKeys(i, CvrtCase.snakeCase));
  }

  return <O>cvrtObjKeys(<I>inp, CvrtCase.snakeCase);
}

export const getRoomDimension = (roomData) => {
  // Use polygon data for the floor
  const polygonCorners = roomData.floors[0].polygonCorners;

  if (!polygonCorners || polygonCorners.length === 0) {
    console.error("No polygon corners found for the floor.");
    return { x: 0, y: 0, z: 0 };
  }

  const xValues = polygonCorners.map(corner => corner[0]);
  const yValues = polygonCorners.map(corner => corner[1]);

  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  const roomWidth = maxX - minX; // width
  const roomDepth = maxY - minY; // depth

  const walls = roomData.walls;
  const roomHeight = walls.length > 0 ? walls[0].dimensions[1] : 0; // Z-axis is height

  return {
    x: Math.abs(roomWidth),  // Width
    y: Math.abs(roomDepth),  // Depth
    z: Math.abs(roomHeight)   // Height
  };
};


export function createLUTTexture(maxIntensity = 200): THREE.DataTexture {
  const lutData = new Uint8Array(256 * 4); // RGBA format

  for (let i = 0; i < 256; i++) {
    const t = i / 255;
    let r, g, b;

    if (t < 0.25) {
      const blend = t / 0.25;
      r = 0;
      g = Math.floor(maxIntensity * blend);
      b = maxIntensity;
    } else if (t < 0.5) {
      const blend = (t - 0.25) / 0.25;
      r = 0;
      g = maxIntensity;
      b = Math.floor(maxIntensity * (1 - blend));
    } else if (t < 0.75) {
      const blend = (t - 0.5) / 0.25;
      r = Math.floor(maxIntensity * blend);
      g = maxIntensity;
      b = 0;
    } else {
      const blend = (t - 0.75) / 0.25;
      r = maxIntensity;
      g = Math.floor(maxIntensity * (1 - blend));
      b = 0;
    }

    lutData[i * 4] = r;
    lutData[i * 4 + 1] = g;
    lutData[i * 4 + 2] = b;
    lutData[i * 4 + 3] = 255; // Alpha channel
  }

  const lutTexture = new THREE.DataTexture(lutData, 256, 1, THREE.RGBAFormat);
  lutTexture.needsUpdate = true;
  return lutTexture;
}


export function createSpotlight(
  position: THREE.Vector3,
  intensity = 100,
  distance = 20,
  angle = Math.PI / 3,
  penumbra = 0.5,
  decay = 2
): THREE.SpotLight {
  
  const spotLight = new THREE.SpotLight(0xffffff, intensity, distance);
  spotLight.position.set(position.x, position.y, position.z);
  spotLight.angle = angle;
  spotLight.penumbra = penumbra;
  spotLight.decay = decay;

  // Enable shadows for the spotlight
  spotLight.castShadow = true;
  spotLight.shadow.bias = -0.0005;
  spotLight.shadow.normalBias = 0.01;
  spotLight.shadow.mapSize.width = 1024;
  spotLight.shadow.mapSize.height = 1024;

  // Set shadow camera parameters
  spotLight.shadow.camera.near = 0.5;
  spotLight.shadow.camera.far = 50;
  spotLight.shadow.camera.fov = 30;
  spotLight.shadow.camera.left = -10;
  spotLight.shadow.camera.right = 10;
  spotLight.shadow.camera.top = 10;
  spotLight.shadow.camera.bottom = -10;

  return spotLight;
}

// Function to get min and max values based on distance only
// TODO : remove this
export const calculateMinMaxDistance = (
  model: THREE.Object3D,
  devicePosition: THREE.Vector3,
  directions: THREE.Vector3[]
): { minDistance: number; maxDistance: number } | null => {
  const raycaster = new THREE.Raycaster();
  let minDistance = Infinity;
  let maxDistance = 0;

  model.traverse((child) => {
    if (child instanceof THREE.Mesh && child.geometry instanceof THREE.BufferGeometry) {
      directions.forEach((direction) => {
        raycaster.set(devicePosition, direction.normalize());
        const intersects = raycaster.intersectObject(child, true);
        if (intersects.length > 0) {
          const distance = intersects[0].distance;
          minDistance = Math.min(minDistance, distance);
          maxDistance = Math.max(maxDistance, distance);
        }
      });
    }
  });

  // Return undefined if there is no valid distances
  if (minDistance === Infinity || maxDistance === 0) {
    return { minDistance: undefined, maxDistance: undefined };
  }

  return { minDistance, maxDistance };
};


// Function to calculate irradiance and update min/max irradiance
export function calculateIrradiance(
  collectedPoints: {
    position: THREE.Vector3;
    zeners: string[];
    distances: number[];
    irradiance?: number;
  }[]
): { minIrr: number; maxIrr: number; updatedPoints: typeof collectedPoints } {
  let minIrr = Infinity;
  let maxIrr = 0;

  const updatedPoints = collectedPoints.map((point) => {
    let totalIrr = 0;
    point.zeners.forEach((_, index) => {
      const dist = point.distances[index];
      const irr = calculateIrrWithAkima(dist);
      totalIrr += irr;
    });

    point.irradiance = totalIrr;

    // Update min and max irradiance on the fly
    if (totalIrr > 0) {
      minIrr = Math.min(minIrr, totalIrr);
      maxIrr = Math.max(maxIrr, totalIrr);
    }

    return point;
  });

  // Return undefined if there is no valid distances
  if (minIrr === Infinity || maxIrr === 0) {
    minIrr = undefined;
    maxIrr = undefined;
  }

  return { minIrr, maxIrr, updatedPoints };
}
