import React from "react";
import { Tooltip, Box, Typography } from "@mui/material";
import * as THREE from "three";

export interface TooltipData {
  x: number;
  y: number;
  pointData: {
    position: THREE.Vector3;
    irradiance?: number;
    zeners: string[];
    distances: number[];
  };
}

interface HeatmapTooltipProps {
  tooltip: TooltipData;
}

export default function HeatmapTooltip({ tooltip }: HeatmapTooltipProps) {
  if (!tooltip) {
    return null;
  }
  const { x, y, pointData } = tooltip;

  const showZeners = pointData.zeners.length > 0;
  const showDistances = pointData.distances.length > 0;

  return (
    <Tooltip
      open={true}
      title={
        <Box sx={{ padding: 1 }}>
          {showZeners && (
            <Typography variant="body2">
              <strong>Zeners:</strong> {pointData.zeners.join(", ")}
            </Typography>
          )}
          {showDistances && (
            <Typography variant="body2">
              <strong>Distances:</strong> {pointData.distances.map(d => d.toFixed(2)).join(", ")}
            </Typography>
          )}
          {pointData.irradiance !== undefined && (
            <Typography variant="body2">
              <strong>Irradiance:</strong> {pointData.irradiance.toExponential(3)} kW/m²
            </Typography>
          )}
        </Box>
      }
      placement="top"
      arrow
    >
      <div style={{ position: "absolute", top: y, left: x }} />
    </Tooltip>
  );
}
