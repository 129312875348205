import { useCallback } from "react";
import { RoomDimension } from "../../pages/SimulateDetails";
import * as THREE from "three";
import { OBJLoader, OrbitControls } from "three/examples/jsm/Addons.js";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sliceIntoChunks(array: number[], chunkSize: number): any[] {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

function getXYComponentsAngle(point: THREE.Vector2, pivot: THREE.Vector2): number {
  const angle = Math.atan2(point.y - pivot.y, point.x - pivot.x);
  return angle;
}

function calcModelXYAlignmentAngle(object: THREE.Mesh): number {
  // vertArray is a 1D collection of raw coordinates of the vertices.
  const vertArray = object.geometry.attributes.position.array;
  // by creating groups of three, we get the vertices (x,y,z)
  const pieces = sliceIntoChunks(vertArray, 3);
  const triangles = sliceIntoChunks(pieces, 3);

  // create hashmap to contain edges created from triange array.
  const edgeMap = new Map();

  let filteredYCoord = undefined;
  // let filteredZCoord = undefined;

  triangles.forEach(triangle => {
    const [vert0, vert1, vert2] = triangle;

    // Compute the normal of the triangle.
    const triangleObj = new THREE.Triangle(
      new THREE.Vector3(...vert0),
      new THREE.Vector3(...vert1),
      new THREE.Vector3(...vert2)
    );
    const norm = new THREE.Vector3();
    triangleObj.getNormal(norm);

    const epsilon = 1e-6; // tolerance for floating-point comparisons

    // The X and Z of the normal should both be 0, indicating that floor is flat
    // There will be triangles on the side of the 3D floor object that we ignore,
    // their normals will have Y directions of 0.
    if ((Math.abs(norm.x) > epsilon || Math.abs(norm.z) > epsilon) && Math.abs(norm.y) > epsilon) {
      throw new Error("Vertical axis is not aligned");
    }

    if (filteredYCoord === undefined) {
      // save Y coord for filtering, this helps convert to 2D space.
      // doesn't matter which vertice's Y coord it gets.
      filteredYCoord = vert0[1];
      return;
    }
    
    // filter out the y-coordinates to convert to 2D space.
    if (vert0[1] === filteredYCoord || vert1[1] === filteredYCoord || vert2[1] === filteredYCoord) {
      return;
    }
    
    const simplifiedVerts = [
      [vert0[0], vert0[2]],  // edge 0
      [vert1[0], vert1[2]],  // edge 1
      [vert2[0], vert2[2]],  // edge 2
    ];

    // These indices refer to the 3 different edges 
    // of the triangle that we need to compare (above).
    const edgeIndices = [[0, 1], [1, 2], [2, 0]];

    edgeIndices.forEach(([i, j]) => {
      // first, we arrange the vertices in each edge
      // so that each edge has a constant string name
      // not impacted the order of the vertices.
      const key = compareVerts2D(simplifiedVerts[i], simplifiedVerts[j]) < 0
        ? `${simplifiedVerts[i]}|${simplifiedVerts[j]}`
        : `${simplifiedVerts[j]}|${simplifiedVerts[i]}`;

      // tally the number of times an edge appears.
      edgeMap.set(key, (edgeMap.get(key) || 0) + 1);
    });
  });

  const uniqueEdges = [];

  for (const [key, value] of edgeMap) {
    // if an edge has value of one, it appeared only once and is unique.
    // push the name string, i.e. the string version of the vertice pair / edge.
    if (value === 1) {
      uniqueEdges.push(key);
    }
  }

  const degLengthObj = uniqueEdges.reduce((acc, edgeStr) => {
    const [vertStr0, vertStr1] = edgeStr.split("|");
    const vertArray0 = vertStr0.split(",").map(Number);
    const vertArray1 = vertStr1.split(",").map(Number);
    const vert0 = new THREE.Vector2(vertArray0[0],vertArray0[1]);
    const vert1 = new THREE.Vector2(vertArray1[0],vertArray1[1]);
    const edgeVector = new THREE.Vector2().subVectors(vert0, vert1);

    const angle = Math.round(getXYComponentsAngle(vert0, vert1) * 1000) / 1000; // round to nearest 0.001
    const length = vert0.distanceTo(vert1);
    const keyString = `${angle}`;
    let updatedDistance = length;

    acc[keyString] = acc[keyString] || { distance: undefined, vectors: [] };

    if (acc[keyString].distance !== undefined) {
      updatedDistance += acc[keyString].distance;
    }

    acc[keyString].vectors.push(edgeVector);
    acc[keyString].distance = updatedDistance;

    return acc;
  }, {});

  const degLengthObjKeys = Object.keys(degLengthObj);

  // map the angles to a new array of objects,
  // where we save the angle, its total distance,
  // and add a new field 'combined distance' which combines the total distance
  // of all angles that form right angles with each other.
  const combinedDistArr = degLengthObjKeys.map(keyStr => {
    let acc = degLengthObj[keyStr].distance;

    const keyObj = degLengthObj[keyStr];
    // only need the first vector as they all have the same deg radians
    const currVector = keyObj.vectors[0];
    for (let i = 0; i < degLengthObjKeys.length; i++) {
      if (keyStr !== degLengthObjKeys[i]) {
        // only need the first vector as they all have the same deg radians
        const vectorToCompare = degLengthObj[degLengthObjKeys[i]].vectors[0];
        // if it is at a right angle, we can add the distance to the combined distance.
        const isRightAngle = findIfRightAngle(currVector, vectorToCompare);
        if (isRightAngle) {
          acc += degLengthObj[degLengthObjKeys[i]].distance;
        }
      }
    }

    const combinedDistObj = {
      ...degLengthObj[keyStr],
      angle: keyStr,
      combDistance: acc,
    };
    return combinedDistObj;
  });

  // finally, select the object with the highest overall combined distance.
  // if there is a tie, select the object with the highest individual total distance.
  const result = combinedDistArr.reduce((maxObj, current) => {
    // if the current item's combined distance is higher than the accumulator's,
    // or if there is a tie and the current item's total distance is higher than the accumulator's,
    // save the current as the result.
    if (
      current.combDistance > (maxObj?.combDistance ?? -Infinity) ||
      (current.combDistance === maxObj.combDistance && current.distance > maxObj.distance)
    ) {
      return current;
    }
    return maxObj;
  }, null);

  return +result.angle;
}

// compare degrees in radians to see if one val is 90° different from another
function findIfRightAngle(vector1: THREE.Vector2, vector2: THREE.Vector2): boolean {
  const epsilon = 0.0001;
  const test1 = vector1.clone();
  const test2 = vector2.clone();
  const norm1 = test1.normalize();
  const norm2 = test2.normalize();
  const dotProduct = norm1.dot(norm2);

  const isRightAngle = (Math.abs(1 - dotProduct) < epsilon) || (Math.abs(dotProduct) < epsilon);
  return isRightAngle;
}

// convert verts into a single value for comparison, 
// so that we can have a constant order for the vertices when referencing a specific edge.
// return 1 if first point is greater, return -1 if second point is greater.
function compareVerts2D(point0: Array<number>, point1: Array<number>): number {
  if (point0.length !== 2 || point1.length !== 2) {
    throw new Error("Wrong vertice dimensions.");
  }
  const sum0 = point0[0] + point0[1];
  const sum1 = point1[0] + point1[1];
  if (sum0 > sum1) {
    return 1;
  } else {
    return -1;
  }
}

export const useModelLoader = (
  scene: THREE.Scene | null,
  camera: THREE.PerspectiveCamera | null,
  renderer: THREE.WebGLRenderer | null,
  modelUrl: string,
  roomDimension: RoomDimension
) => {
  
  const loadModel = useCallback((): Promise<THREE.Object3D | null> => {
    return new Promise((resolve, reject) => {
      if (!scene || !camera || !renderer) {
        reject("Scene, camera, or renderer is not defined");
        return;
      }
  
      const loader = new OBJLoader();
      loader.load(
        modelUrl,
        (object) => {
          let rotationFixDeg = 0;

          console.log("Model loaded successfully");
          object.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              assignMaterialByName(child);
            }

            if (child.name.includes("Floor")) {
              rotationFixDeg = calcModelXYAlignmentAngle(child);
            }
          });
          // Rotate the object for the Z-up configuration  
          // Include the rotation fix degree for the whole room's rotation offset
          object.rotation.set(Math.PI / 2, rotationFixDeg, 0);
          const boundingBox = new THREE.Box3().setFromObject(object);
          const center = boundingBox.getCenter(new THREE.Vector3());
          const size = boundingBox.getSize(new THREE.Vector3());
          // center is aligned with the vertical midpoint
          // object.position.set(-center.x, roomDimension.y / 2 - center.y, -center.z);

          object.position.set(
            - center.x,
            - center.y,
            - center.z + roomDimension.z / 2
          );
          object.updateMatrixWorld(true);

          scene.add(object);
  
          const axes = new THREE.AxesHelper(100);

          // Position axes on the floor
          const floorZPosition = roomDimension.z / 2 - size.z / 2;
          axes.position.set(0, 0, floorZPosition);
          scene.add(axes);
  
          const distance = size.length() * 1;
          camera.position.set(0, distance, distance);
          camera.up.set(0, 0, 1); // Set camera's up direction to Z
          camera.lookAt(new THREE.Vector3(0, 0, 0));
  
          // Set up OrbitControls for smooth rotation
          const controls = new OrbitControls(camera, renderer.domElement);
          controls.minDistance = 2;
          controls.maxDistance = 20;
          controls.enableDamping = true;
          controls.dampingFactor = 0.25;
          controls.screenSpacePanning = false;
          controls.maxPolarAngle = Math.PI / 2;
          controls.update();
  
          resolve(object);
        },
        undefined,
        (error) => {
          console.error("Error loading model:", error);
          reject(error);
        }
      );
    });
  }, [scene, camera, renderer, modelUrl, roomDimension]);
  return { loadModel };
};

const materialFind: Record<string, THREE.MeshPhongMaterial> = {
  wall: new THREE.MeshPhongMaterial({ color: 0xffffff }),
  floor: new THREE.MeshPhongMaterial({ color: 0xf5f5dc }),
  table: new THREE.MeshPhongMaterial({ color: 0xb5a41d }),
  chair: new THREE.MeshPhongMaterial({ color: 0xb58b1d }),
  storage: new THREE.MeshPhongMaterial({ color: 0xBEA589 }),
};

const defaultMaterial = new THREE.MeshPhongMaterial({ color: 0xffffff });

const assignMaterialByName = (mesh: THREE.Mesh) => {
  const material = Object.keys(materialFind).find(key =>
    mesh.name.toLowerCase().includes(key)
  );
  mesh.material = material ? materialFind[material] : defaultMaterial;
};
