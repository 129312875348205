import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable, MRT_PaginationState } from "material-react-table";
import Axios, { CancelTokenSource } from "axios";
import { useSnackbar } from "notistack";
import { getRoomScans, getRoomScansCount } from "../../networking/room_scan";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { PageUrl } from "../App";

function genRoomScansDetailsUrl(scan_id: string): string {
  return `${PageUrl.SimulateDetails}?scan_id=${scan_id}`;
}

export default function Simulate(): React.ReactElement {
  const [roomScans, setRoomScans] = useState([]);
  const [scansCount, setScansCount] = useState<number>(0);
  
  const roomScansCTSRef = useRef<CancelTokenSource | null>(null);
  const scansCountCTSRef = useRef<CancelTokenSource | null>(null);

  const [loading, setLoading] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();

  // State for pagination
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  
  function cancelRequest(cts: React.MutableRefObject<CancelTokenSource>) {
    if (cts.current !== null) {
      cts.current.cancel();
    }
    cts.current = Axios.CancelToken.source();
  }

  async function fetchRoomScans() {
    setLoading(true);
    cancelRequest(roomScansCTSRef);
    cancelRequest(scansCountCTSRef);
    try {
      const offset = pagination.pageIndex * pagination.pageSize;
      const nextRoomScans = await getRoomScans(
        undefined, 
        roomScansCTSRef.current, 
        offset, 
        pagination.pageSize
      );
      const nextRoomScansCount = await getRoomScansCount(undefined, scansCountCTSRef.current);
  
      setRoomScans(nextRoomScans);
      setScansCount(nextRoomScansCount);
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
    }

  }

  useEffect(() => {
    fetchRoomScans();
  }, [pagination.pageIndex]);

  const columns = useMemo(
    () => [
      {
        header: "Scan ID",
        accessorKey: "scan_id",
        enableClickToCopy: true,
      },
      {
        header: "Owner ID",
        accessorKey: "owner_id",
        enableClickToCopy: true,
      },
      {
        header: "Name",
        accessorKey: "name",
        enableClickToCopy: true,
      },
      {
        header: "Upload Date",
        accessorKey: "upload_date",
      },
    ],
    []
  );

  return (
    <div>
      <MaterialReactTable 
        columns={columns}
        data={roomScans ?? []}
        initialState={{ showColumnFilters: true }}
        manualPagination
        onPaginationChange={setPagination}
        rowCount={scansCount}
        state={{
          isLoading: loading,
          pagination,
        }}
        enableRowActions
        renderRowActions={({ row }) => (
          <Box sx={{ display: "flex", gap: "1rem" }}>
            <Link
              to={genRoomScansDetailsUrl(
                row.getValue<string>("scan_id"),
              )}
              target="_blank"
            >
              <OpenInNewIcon />
            </Link>
          </Box>
        )}
      />
    </div>
  );
}