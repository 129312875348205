import Axios, { AxiosResponse, CancelTokenSource } from "axios";
import axios from "./axios";
import { DbQuery, RoomScan } from "../types";

const ROOM_SCANS_URL = "/room_scans";
const GET_ROOM_SCANS_URL = `${ROOM_SCANS_URL}/query`;
const GET_ROOM_SCANS_FILES_URL = `${GET_ROOM_SCANS_URL}/files`;
const GET_ROOM_SCANS_COUNT_URL = `${GET_ROOM_SCANS_URL}/count`;

export type GetRoomScansQuery = DbQuery<RoomScan>;

// Fetch RoomScans data for a list of rooms, retrieves metadata only without scan files.
export async function getRoomScans(
  query: GetRoomScansQuery,
  cancelTokenSource?: CancelTokenSource,
  offset?: number,
  count?: number,
): Promise<RoomScan[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_ROOM_SCANS_URL,
      query,
      {
        params: {
          offset,
          count,
        },
        cancelToken: cancelTokenSource?.token,
      },
    );

    if (!Array.isArray(resp.data)) {
      console.warn(`${GET_ROOM_SCANS_URL} payload is not an array:`, resp.data);
      return [];
    }

    return resp.data as RoomScan[];
  } catch (error) { {
    if(Axios.isCancel(error)) {
      return;
    }
    throw error;
  }}
}

// Fetch RoomScans data with full scan files for rendering purposes.
export async function getRoomScansFiles(
  query: GetRoomScansQuery,
  cancelTokenSource?: CancelTokenSource,
  offset?: number,
  count?: number,
): Promise<RoomScan[]> {
  try {
    const resp: AxiosResponse = await axios.post(
      GET_ROOM_SCANS_FILES_URL,
      query,
      {
        params: {
          offset,
          count,
        },
        cancelToken: cancelTokenSource?.token,
      },
    );
  
    if (!Array.isArray(resp.data)) {
      console.warn(`${GET_ROOM_SCANS_URL} payload is not an array:`, resp.data);
      return [];
    }
  
    return resp.data as RoomScan[];
  } catch (error) { {
    if(Axios.isCancel(error)) {
      return;
    }
    throw error;
  }}
}

  
export async function getRoomScansCount( roomScansQuery: DbQuery<RoomScan> = {}, cancelTokenSource?: CancelTokenSource): Promise<number|null> {
  try {
    const resp: AxiosResponse = await axios.post(GET_ROOM_SCANS_COUNT_URL, 
      roomScansQuery,
      {
        cancelToken: cancelTokenSource?.token,
      });
    return resp.data?.count;
  } catch (error) { {
    if(Axios.isCancel(error)) {
      return;
    }
    throw error;
  }}
};