
const X_VALS_HEIGHT = [2.25, 2.3, 2.35, 2.4, 2.5, 2.6, 2.75, 3];
const X_VALS = X_VALS_HEIGHT.map((x) => x - 1.9 - 0.07); // Subtract detector and Zener height to get actual distance
const Y_VALS = [1.05e-2, 7.58e-3, 5.63e-3, 4.4e-3, 2.9e-3, 2.05e-3, 1.33e-3, 9.25e-4];

const minX = Math.min(...X_VALS);
const maxX = Math.max(...X_VALS);

export class Akima {
  xis: Array<number>;
  yis: Array<number>;
  xyis_len: number;
  extrap_stat: string;

  constructor(
    xis: number[] = X_VALS,
    yis: number[] = Y_VALS,
    extrap_stat: string = "CONST"
  ) {
    if (xis.length !== yis.length) {
      throw new Error("X and Y arrays must have the same length");
    }
    if (xis.length < 3) {
      throw new Error("More points are required for interpolation");
    }

    this.xis = xis;
    this.yis = yis;
    this.xyis_len = xis.length;
    this.extrap_stat = extrap_stat;
  }

  assert(condition ) {
    if (!condition) {
      throw new Error("Assertion error");
    }
  }
  
  findFirstLn ( x ) {
    if (x > this.xis[this.xyis_len - 1]) { 
      return -1; 
    }
  
    this.assert(this.xyis_len >= 2);

    for (let i = this.xyis_len - 2; i >= 0; i--) {
      if (this.xis[i] <= x) { 
        return i; 
      }
    }
    return -1;
  }
  
  calcSlope(x1, x2, y1, y2) {
    const numer = y2 - y1;
    const denom = x2 - x1;
    this.assert(denom != 0);
    return numer / denom;
  }

  akimaItp(x) {

    this.assert(this.xyis_len >= 3);

    // --- Find i ---
    const i = this.findFirstLn(x);
    const out_of_bounds = i < 0 || i >= this.xyis_len - 1;

    if (out_of_bounds) {
      // Out-of-bounds.
      if (this.extrap_stat === "CONST") {
        if (x < this.xis[0]) {
          return this.yis[0];
        } else {
          return this.yis[this.xyis_len - 1];
        }
      }
      if (this.extrap_stat === "NaN") {
        return NaN;
      }
    }
    // --- Calculate slopes ---
    const x_i = this.xis[i];
    const y_i = this.yis[i];
    const x_ip1 = this.xis[i + 1];
    const y_ip1 = this.yis[i + 1];

    const m_i = this.calcSlope(x_i, x_ip1, y_i, y_ip1);
    let m_in2, m_in1, m_ip1, m_ip2;

    if (i === 0) {
      // Generate 2 slopes to the left.
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];
      const m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_in1 = 2 * m_i - m_ip1;
      m_in2 = 2 * m_in1 - m_i;
    } else if (i === 1) {
      // Generate 1 slope to the left.
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];

      m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);
      m_in2 = 2 * m_in1 - m_i;
    } else {
      const x_in2 = this.xis[i - 2];
      const y_in2 = this.yis[i - 2];
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];

      m_in2 = this.calcSlope(x_in2, x_in1, y_in2, y_in1);
      m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);
    }

    if (i === this.xyis_len - 2) {
      const x_in1 = this.xis[i - 1];
      const y_in1 = this.yis[i - 1];
      const m_in1 = this.calcSlope(x_in1, x_i, y_in1, y_i);

      m_ip1 = 2 * m_i - m_in1;
      m_ip2 = 2 * m_ip1 - m_i;
    } else if (i === this.xyis_len - 3) {
      // Generate 1 slope to the right.
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];

      m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_ip2 = 2 * m_ip1 - m_i;
    } else {
      const x_ip2 = this.xis[i + 2];
      const y_ip2 = this.yis[i + 2];
      const x_ip3 = this.xis[i + 3];
      const y_ip3 = this.yis[i + 3];

      m_ip1 = this.calcSlope(x_ip1, x_ip2, y_ip1, y_ip2);
      m_ip2 = this.calcSlope(x_ip2, x_ip3, y_ip2, y_ip3);
    }

    let s_i, s_ip1;

    const s_i_denom = Math.abs(m_ip1 - m_i) + Math.abs(m_in1 - m_in2);

    if (s_i_denom == 0) {
      s_i = (m_in1 + m_i) / 2;
    } else {
      s_i = ((Math.abs(m_ip1 - m_i) * m_in1) + (Math.abs(m_in1 - m_in2) * m_i))
              / s_i_denom;
    }

    const s_ip1_denom = Math.abs(m_ip2 - m_ip1) + Math.abs(m_i - m_in1);
    if (s_ip1_denom == 0) {
      s_ip1 = (m_i + m_ip1) / 2;
    } else {
      s_ip1 = ((Math.abs(m_ip2 - m_ip1) * m_i) + (Math.abs(m_i - m_in1) * m_ip1))
              / s_ip1_denom;
    }
  
    // --- Calculate result polynomial ---

    const a_i = y_i;
    const b_i = s_i;
    const c_i = (3 * m_i - 2 * s_i - s_ip1) / (x_ip1 - x_i);

    let d_i = (s_i + s_ip1 - 2 * m_i);
    d_i /= (x_ip1 - x_i) ** 2;

    const x_d = x - x_i;
    return a_i + b_i * x_d + c_i * (x_d ** 2) + d_i * (x_d ** 3);
  }
}



export function calculateIrrWithAkima(x: number): number {
  const akima = new Akima();

  // Check if x is within bounds
  if (x >= minX && x <= maxX) {
    return akima.akimaItp(x);
  }

  // For out-of-bounds x, calculate the scaling factor a
  const boundaryX = (x < minX) ? minX : maxX;
  const a = akima.akimaItp(boundaryX);
  
  // Use inverse square root
  return a / (x * x);
}

export function getIrradianceCoefficients  () {
  const akima = new Akima();

  const minDistInvSquareCoeff = akima.akimaItp(minX); // Use a from minX
  const maxDistInvSquareCoeff = akima.akimaItp(maxX); // Use b from maxX

  return {minDistInvSquareCoeff, maxDistInvSquareCoeff, minX, maxX}
}