import { useEffect, useState, useCallback } from "react";
import * as THREE from "three";
import { RoomDimension } from "../../pages/SimulateDetails";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

const gridSize = 0.5;

// Place a Zener on the ceiling 
export const useZenerCeilingPos = (
  scene: THREE.Scene | null,
  camera: THREE.PerspectiveCamera | null,
  renderer: THREE.WebGLRenderer | null,
  roomSize: RoomDimension | null,
  canvas: React.RefObject<HTMLCanvasElement>
) => {
  const [zenerPositions, setZenerPositions] = useState<THREE.Vector3[]>([]);
  const [tempZener, setTempZener] = useState<THREE.Mesh | null>(null);
  const loader = new STLLoader();
  const [zener, setZener] = useState<THREE.Mesh>(new THREE.Mesh());
  const zenerModelHeight = roomSize ? roomSize.z - 0.35 : 0;

  async function loadZenerModel() {
    const material = new THREE.MeshPhongMaterial({ 
      color: 0xffffff, 
      opacity: 0.5, 
      transparent: true 
    });
    await loader.load(
      "models/ZenerExternalFeatures.stl",
      function (geometry) {
        const zenerMesh = new THREE.Mesh(geometry, material);
        const scalingFactor = 0.005;
        zenerMesh.scale.set(scalingFactor, scalingFactor, scalingFactor);
        zenerMesh.rotation.x = -Math.PI / 2;
        zenerMesh.name = "createZenerMesh";
        setZener(zenerMesh);
      },
      null,
      (error) => {
        console.log(error);
      }
    );
  }

  const createZenerMesh = useCallback(() => {
    const box = new THREE.Mesh(
      new THREE.BoxGeometry(0.5, 0.5, 0.5),
      new THREE.MeshPhongMaterial({
        color: 0x00ffff,
        opacity: 0.5,
        transparent: true,
      })
    );
    box.name = "createZenerMesh";
    return box;
  }, []);

  const addZenerPosition = useCallback(async () => {
    if (!scene || !roomSize) {
      return;
    } 
    
    const newTempZener = zener.clone();
    // Set initial position at the height of the room
    // 0.35 to account for Zener model height
    newTempZener.position.set(0, 0, zenerModelHeight);
    scene.add(newTempZener);
    setTempZener(newTempZener);

    const size = Math.max(roomSize.x, roomSize.y);
    const gridHelper = new THREE.GridHelper(size, size / gridSize);
    gridHelper.rotation.x = Math.PI / 2; // Rotate to lie on the ceiling
    gridHelper.position.z = roomSize.z;
    scene.add(gridHelper);

  }, [scene, roomSize, createZenerMesh]);

  const fixZenerPosition = useCallback((position: THREE.Vector3) => {
    if (tempZener) {
      setZenerPositions((prev) => [...prev, position]);
      
      (tempZener.material as THREE.MeshPhongMaterial).opacity = 1;
      setTempZener(null);
    }
  }, [tempZener]);

  const snapToGrid = (value: number, gridSize: number): number => {
    return Math.round(value / gridSize) * gridSize;
  };

  const removeAllZeners = useCallback(() => {
    setZenerPositions([]);
    
    scene?.children.forEach((child) => {
      if (child.name.startsWith("createZenerMesh")) {
        child.position.set(0, 0, roomSize ? zenerModelHeight : 0); // Reset each Zener's position to the ceiling
      }
    });
  }, [scene, roomSize]);


  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      if (!camera || !renderer || !roomSize || !scene || !tempZener) {
        return;
      }
      const rect = canvas.current.getBoundingClientRect();
      // Adjust mouse coordinates to account for canvas offset
      const mouse = new THREE.Vector2(
        ((event.clientX - rect.left) / rect.width) * 2 - 1,
        -((event.clientY - rect.top) / rect.height) * 2 + 1
      );

      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(mouse, camera);
      // Define the ceiling plane with Z as height and Y as depth
      const ceilingPlane = new THREE.Plane(new THREE.Vector3(0, 0, -1), roomSize.z); // Plane pointing downward (Z is height)
      const intersectPoint = new THREE.Vector3();
      if (raycaster.ray.intersectPlane(ceilingPlane, intersectPoint)) {
        const snappedX = snapToGrid(intersectPoint.x, gridSize);
        const snappedY = snapToGrid(intersectPoint.y, gridSize);
        tempZener.position.set(snappedX, snappedY, zenerModelHeight);
        renderer.render(scene, camera);
      }
    },
    [camera, renderer, roomSize, scene, tempZener]
  );

  const handleDoubleClick = useCallback(() => {
    if (!tempZener) {
      return;
    }
    const fixedPosition = new THREE.Vector3(tempZener.position.x, tempZener.position.y, tempZener.position.z);
    fixZenerPosition(fixedPosition);
  },
  [fixZenerPosition, tempZener]
  );

  useEffect(() => {
    loadZenerModel();
  }, [scene, roomSize]);

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("dblclick", handleDoubleClick);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [handleMouseMove, handleDoubleClick, scene]);

  return { zenerPositions, addZenerPosition, removeAllZeners };
};
